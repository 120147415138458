@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 84% 4.9%;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --popover: 0 0% 100%;
    --popover-foreground: 222.2 84% 4.9%;

    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;

    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;

    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;

    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;

    --destructive: 0 84.2% 60.2%;
    --destructive-foreground: 210 40% 98%;

    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
    --ring: 222.2 84% 4.9%;

    --radius: 0.5rem;

    --space-cadet: #22223B;

    --ultra-violet: #4A4E69;

    --rose-quartz: #9A8C98;

    --pale-dogwood: #C9ADA7;

    --isabelline: #F2E9E4;

    --font-family: 'Roboto', sans-serif;

    --font-size: 16px;
  }

  .reader-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    position: relative;
    padding-bottom: 15px;
    overflow: hidden;
    /* Prevent scrolling */
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--ultra-violet);
  }

  .back-button {
    background-color: transparent;
    color: var(--isabelline);
    /* border: 1px solid var(--isabelline); */
    border-radius: 4px;
    padding: 6px 12px;
    font-size: 14px;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
  }

  .back-button:hover {
    background-color: var(--isabelline);
    color: var(--ultra-violet);
  }

  .back-button::before {
    margin-right: 5px;
  }

  .words-read {
    color: var(--isabelline);
    padding-right: 10px;
    display: flex;
    align-items: center;
  }

  .immersive-reader {
    flex: 1;
    /* Takes up the remaining space */
    width: 100%;
    /* Ensure it takes the full width available */
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .pagination-controls-container {
    position: fixed;
    top: 0;
    /* Change from bottom: 0 to top: 0 */
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    /* Add this line to align content to the top */
    z-index: 2000;
    background-color: rgba(255, 255, 255, 0.8);
    padding: 10px 0;
    /* Remove bottom padding */
    margin-bottom: 15px;
    /* Add 15px margin at the bottom */
  }

  .pagination-controls {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 3000;
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
    background-color: transparent;
  }

  .pagination-controls button {
    background-color: var(--ultra-violet);
    color: var(--isabelline);
    border: none;
    padding: 8px 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    flex: 1;
    /* Added to make the buttons take up equal space */
    max-width: 120px;
    /* Added to limit the maximum width of the buttons */
  }

  .pagination-controls button:hover,
  .pagination-controls button:focus {
    background-color: var(--rose-quartz);
  }

  .pagination-controls button:active {
    background-color: var(--pale-dogwood);
  }

  @media (max-width: 600px) {
    .header {
      padding: 8px 10px;
    }

    .back-button {
      font-size: 12px;
      padding: 4px 8px;
    }

    .back-button::before {
      margin-right: 3px;
    }

    .pagination-controls-container {
      top: 0;
      /* Change from bottom: 0 to top: 0 */
      padding: 10px 0;
      /* Remove bottom padding */
      margin-bottom: 15px;
      /* Add 15px margin at the bottom */
    }

    .pagination-controls {
      max-width: 100%;
      /* Allow full width on smaller screens */
      padding: 0 10px;
      /* Reduce side padding */
    }

    .pagination-controls button {
      font-size: 14px;
      /* Slightly larger font for better touchability */
      padding: 8px 12px;
      /* Increase padding for larger touch targets */
      max-width: none;
      /* Remove max-width constraint */
    }
  }

  @media (min-width: 601px) and (max-width: 1024px) {
    .back-button {
      font-size: 12px;
      padding: 6px 10px;
    }

    .pagination-controls-container {
      padding: 10px 0;
      /* Remove bottom padding */
      margin-bottom: 15px;
      /* Add 15px margin at the bottom */
    }

    .pagination-controls {
      max-width: 250px;
      /* Adjusted to make the pagination controls optimally wide for iPad */
    }

    .pagination-controls button {
      font-size: 14px;
      padding: 0px 12px;
      max-width: 100px;
      /* Adjusted to make the buttons optimally sized for iPad */
    }

    .immersive-reader {
      padding: 0 10px;
      /* Add padding to prevent content from touching the edges */

    }
  }
}

.language-container {
  display: inline-block;
  height: .88em;
  /* Adjust height as needed */
  position: relative;
  white-space: nowrap;
  /* Prevent text wrapping */
  padding-left: 10px;
  width: 200px;
  text-align: left;
}

@keyframes spin-slow {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.animate-spin-slow {
  animation: spin-slow 120s linear infinite !important;
}

@keyframes roll-out {
  0% {
    transform: translateY(0) rotateX(0deg);
    opacity: 1;
  }

  50% {
    transform: translateY(-50%) rotateX(-45deg);
    opacity: 0.5;
  }

  100% {
    transform: translateY(-100%) rotateX(-90deg);
    opacity: 0;
  }
}

@keyframes roll-in {
  0% {
    transform: translateY(100%) rotateX(90deg);
    opacity: 0;
  }

  50% {
    transform: translateY(50%) rotateX(45deg);
    opacity: 0.5;
  }

  100% {
    transform: translateY(0) rotateX(0deg);
    opacity: 1;
  }
}

.animate-roll-out {
  display: inline-block;
  animation: roll-out 1s forwards;
}

.animate-roll-in {
  display: inline-block;
  animation: roll-in 1s forwards;
}

.active-page {
  background-color: #f0f0f0;
  font-weight: bold;
}

.pagination-container {
  display: flex;
  justify-content: center;
  margin-top: 5px;
}

.gradient-header {
  /* Reusable gradient header class */
  background: linear-gradient(270deg, #1F1F33 50%, #5D5D99 100%);
}


.dark {
  --background: 222.2 84% 4.9%;
  --foreground: 210 40% 98%;

  --card: 222.2 84% 4.9%;
  --card-foreground: 210 40% 98%;

  --popover: 222.2 84% 4.9%;
  --popover-foreground: 210 40% 98%;

  --primary: 210 40% 98%;
  --primary-foreground: 222.2 47.4% 11.2%;

  --secondary: 217.2 32.6% 17.5%;
  --secondary-foreground: 210 40% 98%;

  --muted: 217.2 32.6% 17.5%;
  --muted-foreground: 215 20.2% 65.1%;

  --accent: 217.2 32.6% 17.5%;
  --accent-foreground: 210 40% 98%;

  --destructive: 0 62.8% 30.6%;
  --destructive-foreground: 210 40% 98%;

  --border: 217.2 32.6% 17.5%;
  --input: 217.2 32.6% 17.5%;
  --ring: 212.7 26.8% 83.9%;
}

@layer utilities {
  .animated-gradient {
    @apply bg-gradient-to-r;
    background-size: 400% 400%;
    background-image: linear-gradient(to right, #4682B4, #702963, #D2B48C);
    animation: gradientAnimation 12s ease infinite;
    transition: background-position 0.5s ease, background-color 0.5s ease;
    /* For smoother transitions */

    &:hover {
      background-color: #22223B;
      background-image: none;
      /* Remove the gradient when hovering */
    }
  }
}

@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }

  25% {
    background-position: 100% 50%;
  }

  50% {
    background-position: 0% 50%;
  }

  75% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
    /* Match the 0% keyframe */
  }
}



@layer base {
  * {
    @apply border-border;
  }

  body {
    @apply bg-background text-foreground;
  }
}